import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "../../utils/api.service";
import { setLoader } from "./masterSlice";

//landing page
export const getLandingProperty = createAsyncThunk("landingProperties", async ({page, filters }, { dispatch }) => {
  try {
    dispatch(setLoader(true));
    let data = await API.landingProperty(page, filters);
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});

//home page
export const getHomeProperty = createAsyncThunk("homeProperties", async ({ page , filters = {} } = {}, { dispatch }) => {
console.log('page , filters :', page , filters);
  try {
    dispatch(setLoader(true));
    let data = await API.homeProperty(page, filters );
    dispatch(setLoader(false));
    return data;
  } catch (error) {
    dispatch(setLoader(false));
    throw error;
  }
});


const initialState = {
  landingProperties: {
    data: [],
    error: null,
  },
  homeProperties: {
    data: [],
    error: null,
  },
};

const landingPropertySlice = createSlice({
  name: "landingProperty",
  initialState,
  reducers: {
    setLandingProperties: (state) => {
      state.landingProperties.data = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandingProperty.fulfilled, (state, action) => {
        state.landingProperties.data = action.payload.data;
      })
      .addCase(getLandingProperty.rejected, (state, action) => {
        state.landingProperties.error = action.error.message;
      })
      .addCase(getHomeProperty.fulfilled, (state, action) => {
        state.homeProperties.data = action.payload.data;
      })
      .addCase(getHomeProperty.rejected, (state, action) => {
        state.homeProperties.error = action.error.message;
      })
  }
});

export const { setLandingProperties } = landingPropertySlice.actions;
export default landingPropertySlice.reducer;
